import classes from "./Component.module.scss";
import loading from "../../../assets/imgs/loading.gif";
import { renderCollage } from "./Collage";
import filter from "../../../assets/imgs/filter.png";
import Loading from "../../../components/Loading";
import LoadingSpin from "../../../components/LoadingSpin";
import CheckBox from "../../../components/CheckBox";
import { renderChat } from "./Chat";
import { renderNotes } from "./Notes";
import { renderScreenShot } from "./ScreenShot";

export const renderLoading = () => {
    return <div className={classes.container}>
        <LoadingSpin />
    </div>
}

export const renderWindow = (_this) => {
    if (_this.state.isloading) {
        return renderLoading();
    }
    switch (_this.state.chosenWindow) {
        case 'loading':
            return renderLoading();
        case 'collage':
            return renderCollage(_this);
        case 'chat':
            return renderChat(_this);
        case 'notes':
            return renderNotes(_this);
        case 'screenshot':
            return renderScreenShot(_this);
        default:
            return renderLoading();
    }
}

export const renderSearch = () => {
    return <div className={classes.search}>
        <div className={classes.search_c}>
            <input
                type="text"
                placeholder='Search'
                onChange={(event) => {
                    console.log(event);
                }}
            />
            {/* <div className={classes.search_c_filter}> */}
            {/* <img alt="filter" src={filter} /> */}
            {/* <span>by id</span> */}
            {/* <span>by message</span> */}
            {/* <span>by chat</span> */}
            {/* <span>by warning</span> */}
            {/* </div> */}
        </div>
        {/* <div className={classes.search_c}>
            <div className={classes.search_c_check}>
                <div className={classes.search_c_check_c}>
                    <CheckBox
                        id="online"
                        name="online"
                        onChange={() => { }}
                        checked={true}
                    />
                    <span>Online</span>
                </div>
            </div>
        </div> */}
    </div>
}

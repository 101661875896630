import { fetchDetailParticipant } from "./Action";
import classes from "./Participant.module.scss"
import star from "../../../assets/imgs/staryellow.png";
import flag from "../../../assets/imgs/flagcolor.png";

export const renderParticipant = (_this) => {
    // console.log("PAR = ", _this.state.participants);

    let el = [];

    for (let p in _this.participants) {
        const par = _this.participants[p];
        const isOnline = (par.status === 'online')
        el.push(<div
            key={par.id}
        >
            <div
                id={par.uuid}
                className={[
                    classes.participant_c,
                    _this.state.chosen.uuid === par.uuid ? classes.chosen : null
                ].join(" ")}
                onClick={() => {
                    _this.setState({
                        isloading: true,
                        chosen: {
                            id: par.id,
                            uuid: par.uuid
                        }
                    });
                    fetchDetailParticipant(_this, par.uuid);
                }}
            >
                <img alt="img" width={"50px"} src={par.img} />
                <div>
                    <span className={classes.id}>{par.id}</span>
                    <span className={classes.name}>{par.name}</span>
                    {par.chat && <span className={classes.chat}>{par.chat}</span>}
                    <div className={classes.info}>
                        {isOnline ?
                            <div className={classes.online}>online</div>
                            : <div className={classes.offline}>offline</div>
                        }<div className={classes.star}>
                            <img alt="star" src={star} />
                        </div>
                        <div className={classes.flag}>
                            <img alt="flag" src={flag} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.separator}></div>
        </div>
        )
    }
    return <div className={classes.participant}>
        {el}
    </div>
}

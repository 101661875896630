import * as constants from "./constants.js";
import io from "socket.io-client";
import ProktorWebsocket from "./ProktorWebsocket";
import { Debug, Err, Info, Warning } from "../logger.js";

class WebRTCPrivateCall {
  destructor() {
    // This function is called when the object is destroyed
    Debug(`${this.name} is destroyed`);
  }

  constructor(
    registerId,
    registerName,
    videoRouter,
    event,
    isParticipant = true,
    streamLocal = null,
    onDisconnect = null
  ) {
    this.iceId = 0;
    this.connectedUserDetails = null;
    this.peerConnection = null;
    this.onDisconnectEvent = onDisconnect;
    this.videoRouter = videoRouter;
    this.savedStats = null;
    this.userId = "";
    this.proctorId = "";

    this.prefix = `private_`
    this.localStream = streamLocal;

    this.ice_configuration = {
      iceServers: [
        {
          urls: `stun:${videoRouter.ip_address}:3478`,
          username: `${videoRouter.username}`,
          credential: `${videoRouter.password}`
        },
        {
          urls: `turn:${videoRouter.ip_address}:3478`,
          username: `${videoRouter.username}`,
          credential: `${videoRouter.password}`
        }
      ]
    }
    Info("****** ice configuration = ", this.ice_configuration);
    if (isParticipant) {
      registerId = `${this.prefix}${registerId}`
      this.userId = registerId;
      this.createPeerConnection(true)
    } else {
      this.proctorId = registerId;
      this.createPeerConnection(false);
    }

    try {
      this.socket = io(`/?ipaddress=${videoRouter.ip_address}`, {
        path: "/socket.io"
      });
    } catch (err) {
      Err("ERROR IS CAPTURED HERE");
    }
    this.wss = new ProktorWebsocket(
      this.socket,
      registerId,
      registerName,
      isParticipant,
      event
    );

    Debug("ice configuration=", this.ice_configuration);
    this.socket.on("pong", (data) => {
      Debug("PONG", data);
    })
    this.socket.on("pre-offer", (data) => {
      Debug("[" + this.userId + "] recv pre-offer proctorer->participant", data);
      this.handlePreOffer(data);
    });

    this.socket.on("pre-offer-failed", (data) => {
      setTimeout(() => {
        this.wss.close();
        this.socket.disconnect();
        this.onDisconnectEvent("sendPreOffer Failed");
      }, 5000);
    });

    this.socket.on("pre-offer-answer", (data) => {
      this.handlePreOfferAnswer(data);
    });

    this.socket.on("user-hanged-up", () => {
      this.handleConnectedUserHangedUp();
    });
    this.socket.on("callee-socket-id", (data) => {
      this.fillCalleeId(data["calleeSocketId"]);
    });

    this.socket.on("webRTC-signaling", (data) => {
      const { toId, fromId } = data;
      switch (data.type) {
        case constants.webRTCSignaling.OFFER:
          this.handleWebRTCOffer(toId, fromId, data);
          break;
        case constants.webRTCSignaling.ANSWER:
          this.handleWebRTCAnswer(toId, fromId, data);
          break;
        case constants.webRTCSignaling.ICE_CANDIDATE:
          this.handleWebRTCCandidate(toId, fromId, data);
          break;
        default:
          return;
      }
    });

    this.socket.on("disconnect", () => {
      this.socket.disconnect();
      if (this.onDisconnectEvent !== null) {
        this.onDisconnectEvent("diconnect event captured");
      } else {
        Debug("onDisconnectEvent is not set");
      }
    });
    this.socket.on("connect_error", () => {
      this.socket.disconnect();
      this.onDisconnectEvent("connect_error event captured");
    });
  }

  getVideoRouter() {
    return this.videoRouter;
  }

  getSavedStats() {
    if (!this.savedStats) {
      return null
    }

    return this.savedStats.length > 0 ? this.savedStats : null;
  }

  getStats() {
    if (this.peerConnection === undefined) {
      this.savedStats = [];
      return;
    }
    let r = [];
    this.peerConnection?.getStats().then(stats => {
      stats.forEach(report => {
        r.push(report);
      });
      this.savedStats = r;
    });
  }

  close() {
    Info("close webrtc, readyState=", this.socket);
    this.socket.disconnect();
    this.socket.close();
    if (this.peerConnection !== null) {
      Info("CLOSE PEER CONNECTION");
      if (this.peerConnection !== undefined) {
        this.peerConnection.close();
      }
      this.peerConnection = null;
      delete this.peerConnection;
    }
  }

  createPeerConnection(isReceivingMedia = true) {
    Info("***** CREATE PEER CONNECTION ICE CONFIGURATION = ", this.ice_configuration);
    this.peerConnection = new RTCPeerConnection(this.ice_configuration);

    this.peerConnection.ondatachannel = (event) => {
      const dataChannel = event.channel;

      dataChannel.onopen = () => {
        Info(
          "[" +
          this.userId +
          "] peer connection is ready to receive data channel messages"
        );
        Debug("THIS IS ON OPEN");
        this.socket.disconnect();
        if (this.onDisconnectEvent !== null) {
          this.onDisconnectEvent("established");
        }
      };

      dataChannel.onmessage = (event) => {
        // const message = JSON.parse(event.data);
      };
    };

    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        this.wss.sendDataUsingWebRTCSignaling({
          type: constants.webRTCSignaling.ICE_CANDIDATE,
          candidate: event.candidate,
          fromId: this.connectedUserDetails.fromId,
          toId: this.connectedUserDetails.toId
        });
      }
    };

    this.peerConnection.onconnectionstatechange = (event) => {
      if (this.peerConnection === undefined) {
        return;
      }
      Info("onconnection state change state=", this.peerConnection.connectionState);
      if (this.peerConnection.connectionState === "connected") {
        // TODO document why this block is empty
        Debug("PEER CONNECTION IS CONNECTED");
        this.onDisconnectEvent("connected");
      } else if (
        this.peerConnection.connectionState === "disconnected" ||
        this.peerConnection.connectionState === "failed") {
        this.peerConnection.close();
        this.peerConnection = null;
        delete this.peerConnection;

        // do not change "webrtc disconnected", it's param for the callback
        this.onDisconnectEvent("webrtc disconnected");
      } else {
        console.log(this.peerConnection.connectionState);
        this.onDisconnectEvent(this.peerConnection.connectionState);
      }
    };

    if (isReceivingMedia === true) {
      Info(
        "[" +
        this.proctorId +
        "][onPeerConnection] THIS SIDE IS RECEIVING REMOTE STREAM"
      );

      // receiving tracks
      const remoteStream = new MediaStream();
      this.remoteStream = remoteStream;
      let remoteAudio = null;
      const elAudioId = `audioCaller`;
      remoteAudio = document.getElementById(elAudioId);
      if (remoteAudio !== null) {
        Info(`[${elAudioId}] remote audio is not null, assign RemoteStream`, remoteAudio);
        remoteAudio.srcObject = remoteStream;
      }
      this.peerConnection.ontrack = (event) => {
        remoteStream.addTrack(event.track);
      };
    } else {
      Debug(
        "[" +
        this.userId +
        "][onPeerConnection] THIS SIDE DID NOT RECEIVE REMOTE MEDIA"
      );
    }

    // add our stream to peer connection
    Debug(
      "[" + this.userId + "] connected user = ",
      this.connectedUserDetails
    );

    const localStream = this.localStream;

    if (localStream !== null) {
      for (const track of localStream.getTracks()) {
        this.peerConnection.addTrack(track, localStream);
      }
    } else {
      Debug("[" + this.proctorId + "] localStream is empty");
    }
  }

  sendPing(toId) {
    Debug("Ping to ", toId);
    this.wss.sendPing(toId);
  }

  sendPreOffer(callType, userId, proctorId) {
    userId = `${this.prefix}${userId}`
    this.connectedUserDetails = {
      callType,
      userId: userId,
      proctorId: proctorId,
      fromId: proctorId,
      toId: userId,
    };

    this.userId = userId;
    const data = {
      callType,
      userId,
      proctorId,
    };

    this.wss.sendPreOffer(data);
  }

  handlePreOffer(data) {
    const { callType, proctorId, userId } = data;

    this.connectedUserDetails = {
      callType,
      userId: userId,
      proctorId: proctorId,

      fromId: userId,
      toId: proctorId,
    };

    this.sendPreOfferAnswer(
      constants.preOfferAnswer.CALL_ACCEPTED,
      proctorId
    );

    // settimeout 1000, 
    // kirim sendwebrtcoffer apabila media adalah dari peserta
  }

  sendPreOfferAnswer(preOfferAnswer, proctorId) {
    const data = {
      proctorId: proctorId,
      preOfferAnswer: preOfferAnswer,
    };
    this.wss.sendPreOfferAnswer(data);
  }

  handlePreOfferAnswer(data) {
    const { preOfferAnswer } = data;
    if (preOfferAnswer === "CALLEE_NOT_FOUND") {
      Warning("[" + this.proctorId + "] Callee is not found, stop here");
    } else {
      Debug(`[${this.proctorId}], userId=${this.userId} create Peer Connection`);

      // ini adalah awal mula dari transaksi SDP
      // pindahkan ini apabila diperlukan
      // createOffer berhasil mendapatkan jalur media
      // createAnswer kadang tidak bisa
      this.sendWebRTCOffer(this.proctorId, this.userId);
    }
  }

  async sendWebRTCOffer(fromId, toId) {
    const offer = await this.peerConnection.createOffer();
    await this.peerConnection.setLocalDescription(offer);
    this.wss.sendDataUsingWebRTCSignaling({
      type: constants.webRTCSignaling.OFFER,
      offer: offer,
      fromId: fromId,
      toId: toId
    });
  }

  async handleWebRTCOffer(fromId, toId, data) {
    if (this.peerConnection === undefined) {
      Err("[" + this.userId + "] peerConnection is not created");
      return;
    }
    Info("OFFER = ", data.offer);
    await this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));
    const answer = await this.peerConnection.createAnswer();
    await this.peerConnection.setLocalDescription(answer);
    this.wss.sendDataUsingWebRTCSignaling({
      type: constants.webRTCSignaling.ANSWER,
      answer: answer,
      fromId: fromId,
      toId: toId
    });
  }

  async handleWebRTCAnswer(fromId, toId, data) {
    if (this.peerConnection === undefined) {
      Err("[" + this.userId + "] peerConnection is not created");
      return;
    }
    await this.peerConnection.setRemoteDescription(data.answer);
  }

  async handleWebRTCCandidate(fromId, toId, data) {
    if (this.peerConnection === undefined) {
      Debug("[" + this.userId + "] peerConnection is not created");
      return;
    }
    try {
      await this.peerConnection.addIceCandidate(data.candidate);
    } catch (err) {
      Err("error occured when trying to add received ice candidate", err);
    }
  }
}

export default WebRTCPrivateCall;

import { Debug, Err, Info } from "../../libs/logger";
import WebRTCPrivateCall from "../../libs/WebRTC/WebRTCPrivateCall";
import * as constants from "../../libs/WebRTC/constants.js";
import classes from "./PrivateCall.module.scss";
import endcall from "../../assets/imgs/endcall.png";
import ringtone from "../../assets/sounds/ringtone.mp3";
import { convertDurationToBetterFormatInt2 } from "../../libs/utils.js";
import { Rnd } from "react-rnd";

export const __PrivateCall__ = function (_this) {
    this.webrtc = null;
    this.proctorId = "";
    this.proctorName = "";
    this.videoRouter = "";

    this.userId = "";
    this.call_status = "offline";
    this.callParticipant = (proctorId, userId, videoRouter) => {
        this.proctorId = proctorId;
        this.userId = userId;
        this.videoRouter = videoRouter

        this.webrtc = new WebRTCPrivateCall(
            this.proctorId,
            this.proctorName,
            this.videoRouter,
            () => {
                this.webrtc.sendPreOffer(
                    constants.callType.VIDEO_PERSONAL_CODE,
                    this.userId,
                    this.proctorId,
                    this.proctorName
                );
            },
            false,
            _this.state.userMediaStream,
            (status) => {
                Debug("WEBRTC ON DISCONNECT", status);
                if (this.userId !== undefined) {
                    this.call_status = status;
                }
                if (status !== "established") {
                    this.call_status = status;
                    this.establishedStartTs = 0;
                } else if (status === "established") {
                    this.call_status = "established"
                    this.currentTime = 0;
                    this.establishedStartTs = Date.now();
                } else {
                    this.webrtc = null;
                }
            })
    }
    this.getCallStatus = () => {
        return this.call_status
    }
    this.hangup = () => {
        this.webrtc?.close();
    }
    this.call = (proctorerId, participantId, videoRouter) => {
        _this.commonWs.sendStartWebRTCPrivate(
            proctorerId,
            participantId,
            `private_${proctorerId}_${participantId}`,
            videoRouter)
    }

    this.renderOnCall = () => {
        return _this.state.privateCallState.callStatus !== "hangup" &&
            <Rnd
                default={{
                    x: 10,
                    y: 10,
                    width: 320,
                    // height: 200,
                }}
                minWidth={200}
                minHeight={100}
                bounds="window"
                style={{ zIndex: "4000" }}
            >
                <div style={{ width: '100%', height: '100%' }}>
                    {this.renderOnCallEl()}
                </div>
            </Rnd>
    }

    this.renderOnCallEl = () => {
        const textInfo = (txt) => {
            switch (txt) {
                case "dialing":
                    return "Dialing ...";
                case "connecting":
                    return "Connecting ...";
                case "connected":
                    return "Connected";
                default:
                    return ""
            }
        }
        const name = _this.state.participants[_this.state.privateCallState.participantId]?.username;
        // return _this.state.privateCallState.callStatus !== "hangup" && 
        return <div className={classes.container}>
            {_this.state.privateCallState.callStatus === "dialing" && <audio autoPlay loop>
                <source src={ringtone} type="audio/mpeg" />
                Your browser does not support the audio tag.
            </audio>
            }
            <div className={classes.container_header}>
                <span className={classes.id}>{_this.state.privateCallState.participantId} ({name})</span>
                {/* <span>{name}</span> */}
                <span className={classes.info} >{textInfo(_this.state.privateCallState.callStatus)}</span>
                {_this.state.privateCallState.callStatus === 'error' &&
                    <span>{_this.state.privateCallState.errorMessage}</span>
                }
                {_this.state.privateCallState.callStatus === 'connected' &&
                    <span className={classes.duration}>
                        {convertDurationToBetterFormatInt2(_this.state.privateCallState.durationConnected)}
                    </span>
                }
            </div>
            <div className={classes.container_video}>
                <div>
                    <video id="facecall"
                        autoPlay
                    />
                </div>
                <div>
                    <video id="screencall"
                        autoPlay
                    />
                </div>
            </div>
            <div className={classes.container_bottom}
                onClick={() => {
                    hangUpAll(_this);
                }}
            >
                <img title="hang up" alt="endcall" src={endcall} className={classes.endcall} />
            </div>
        </div>
    }
}

let callInterval = null;

const hangUpAll = (_this) => {
    _this._privatecall.hangup();
    clearInterval(callInterval);
    _this.setState(prevState => ({
        privateCallState: {
            ...prevState.privateCallState,
            durationConnected: 0,
            durationDialing: 0,
            callStatus: 'hangup'
        }
    })
    );
}

export const onCallParticipant = (_this, participantId) => {
    const callStatus = _this._privatecall.getCallStatus();
    Info("participant state = ", _this.context.profile.id, participantId);
    // console.log("call status = ", callStatus);
    console.log(" check ", _this.state.privateCallState.callStatus)
    if (callStatus === 'connected' || callStatus === "established") {
        hangUpAll(_this);
        // _this._privatecall.hangup();
        // clearInterval(callInterval);
        // _this.setState(prevState => ({
        //     privateCallState: {
        //         ...prevState.privateCallState,
        //         durationConnected: 0,
        //         durationDialing: 0,
        //         callStatus: 'hangup'
        //     }
        // })
        // );
    } else if (_this.state.privateCallState.callStatus === 'dialing') {
        hangUpAll(_this);
        // _this._privatecall?.hangup();
        // clearInterval(callInterval);
        // _this.setState(prevState => ({
        //     privateCallState: {
        //         ...prevState.privateCallState,
        //         durationConnected: 0,
        //         durationDialing: 0,
        //         callStatus: 'hangup'
        //     }
        // }));
    } else {
        const videoRouter = _this.pm.getVideoRouterCandidate()
        _this.setState(prevState => ({
            privateCallState: {
                ...prevState.privateCallState,
                callStatus: 'connecting',
                participantId: participantId,
                durationConnected: 0,
                durationDialing: 0,
            }
        }));

        setTimeout(() => {
            _this._privatecall.call(_this.context.profile.id,
                participantId,
                videoRouter
            )
        }, 5000);

        setTimeout(() => {
            _this.setState(prevState => ({
                privateCallState: {
                    ...prevState.privateCallState,
                    callStatus: 'dialing'
                }
            }));
        }, 1000);

        clearInterval(callInterval);

        callInterval = setInterval(() => {
            if (_this.state.privateCallState.callStatus === "dialing") {
                _this.setState(prevState => ({
                    privateCallState: {
                        ...prevState.privateCallState,
                        callConnectedStatus: _this._privatecall.getCallStatus(),
                        durationDialing: prevState.privateCallState.durationDialing + 1,
                    }
                }));
                if (_this.state.privateCallState.durationDialing > 60) {
                    _this.setState(prevState => ({
                        privateCallState: {
                            ...prevState.privateCallState,
                            callStatus: "error",
                            errorMessage: "Tidak dapat terhubung dengan peserta."
                        }
                    }));
                    clearInterval(callInterval);
                    setTimeout(() => {
                        _this._privatecall?.hangup();
                        _this.setState(prevState => ({
                            privateCallState: {
                                ...prevState.privateCallState,
                                callStatus: "hangup",
                                errorMessage: ""
                            }
                        }));
                    }, 3000);
                }
            }
            const callStatus = _this._privatecall.getCallStatus();
            if (callStatus === "connected") {
                _this.setState(prevState => ({
                    privateCallState: {
                        ...prevState.privateCallState,
                        callStatus: "connected",
                        durationConnected: prevState.privateCallState.durationConnected + 1,
                    }
                }));

                // connect to srcObject
                // const type = `liveVideo`
                // const elid = `${type}_${_this.context.profile.id}${participantId}`
                // const el = document.getElementById(elid);
                // document.getElementById("facecall").srcObject = el.srcObject;
                loadVideo("facecall", "liveVideo")
                loadVideo("screencall", "screenCapture")
            }
        }, 1000)
    }

    const loadVideo = (elid, type) => {
        // const type = `liveVideo`
        const elxid = `${type}_${_this.context.profile.id}${participantId}`
        const el = document.getElementById(elxid);
        if (document.getElementById(elid).srcObject === null) {
            document.getElementById(elid).srcObject = el.srcObject;
        }
    }
}
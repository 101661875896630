import { AttentionAlert, DecisionAlert, ErrorAlert } from "../../components/Alert";
import { Lang } from "../../libs/Language";
import { Debug, Err, Info } from "../../libs/logger";
import { elementToCanvas, fetchExamProfile, fetchParticipantByParticipantId, fetchSetAction, getIPAddress, getVideoQualityOptions, urlProctorerRandom } from "../../libs/proctor_utils";
import { avgResponseTime, convertTimestampToTime, getAccessToken } from "../../libs/utils";

export const __Action__ = function (_this, classes) {
    _this.lastHelpButtonPushedTs = 0;
    _this.lastSendImageTs = 0;
    _this.mediaStreamTs = {
        userMedia: {
            ts: -1,
            try: 0
        },
        displayMedia: {
            ts: -1,
            try: 0
        }
    }
    _this.mode = _this.props.mode;

    /*
    _this.lastSendImageWarningTs
    this variable is intended to prevent, save image operations 
    to be done too often
    */
    _this.lastSendImageWarningTs = 0;

    _this.directUser = JSON.parse(sessionStorage.getItem("directUser"));

    this.onExam = () => {
        return <div className={classes.preparation_countDown}>
            <div className={classes.preparation_countDown_text}>
            </div>
            <div className={classes.preparation_countDown_timer}>
                <h2 style={{ fontWeight: "bold" }}>
                    {convertTimestampToTime(_this.currentTs)}
                </h2>
            </div>
        </div>
    }

    this.onCountDown = () => {
        const { displayTimer } = _this.state;
        if (displayTimer === "menuju waktu ujian") {
            return _this._component.renderCountDownTime();
        } else if (displayTimer === "ujian" || displayTimer === "selesai") {
            return this.onExam();
        }
    }



    this.onHelp = () => {
        // if (true) {
        if (_this.lastHelpButtonPushedTs === 0 || Date.now() - _this.lastHelpButtonPushedTs > 60 * 2 * 1000 /*5 minutes */) {
            if (_this.chatWs) {
                _this.chatWs.sendChat(_this.context.profile.id, "validation", "validation", null, _this.context.profile.folder_rand);
            }
            _this.setState({ helpAlert: true })
            _this.lastHelpButtonPushedTs = Date.now();
        }
        // } 
        // else {
        //     for (let i = 0; i < 10000; i++) {
        //         _this.commonWs.sendChat(_this.context.profile.id, "validation", "validation");
        //         _this.commonWs.sendChat(_this.context.profile.id, "broadcast", "test load  " + i);
        //         _this.setState({ helpAlert: true })
        //     }
        // }
    }

    this.onLogout = async (confirm = false) => {

        if (confirm) {
            const confirmed = await DecisionAlert("Are you sure to quit now?");
            if (!confirmed) {
                return;
            }
        }

        _this.context.logout();
        if (_this.state.userMediaStream !== null && _this.state.userMediaStream !== undefined) {
            _this.state.userMediaStream.getTracks().forEach(
                function (track) {
                    track.stop();
                }
            )
        }

        // SCREEN
        if (_this.mode !== "mobile" && _this.state.displayMediaStream !== null && _this.state.displayMediaStream !== undefined) {
            _this.state.displayMediaStream.getTracks().forEach(
                function (track) {
                    track.stop();
                }
            )
        }

        clearInterval(_this.updateTsInterval);
        // clearInterval(_this.timerInterval);
        clearInterval(_this.socketInterval);
        // clearInterval(_this.warningInterval);

        // send on logout 
        if (_this.commonWs !== null) {
            _this.commonWs?.sendLogout(_this.context.profile.id);
        }
        // no need to call reload
        // window.location.reload();
    }


    this.onUpdatePrivateProctorer = (data) => {
        if (data?.result !== null) {
            data = data.result;

            if (data.xrcp) {
                data.proctor_id = `proctor${data.xrcp}`;
            }

            let ppid = _this.state.privateProctorerId;
            if (!ppid.includes(data.proctor_id)) {
                ppid.push(data.proctor_id);
                ppid.push(`${data.proctor_id}#01`);
                ppid.push(`${data.proctor_id}#02`);
                ppid.push(`${data.proctor_id}#03`);
            }
            _this.setState({
                participantProfile: data,
                privateProctorerId: ppid,
            })
        }
    }

    this.onUserPreparationDone = (data) => {
        if (data !== null) {
            // data = data.result;

            // let ppid = _this.state.privateProctorerId;
            // if (!ppid.includes(data.proctor_id)) {
            //     ppid.push(data.proctor_id);
            //     ppid.push(`${data.proctor_id}#01`);
            //     ppid.push(`${data.proctor_id}#02`);
            //     ppid.push(`${data.proctor_id}#03`);
            // }
            _this.setState({
                // participantProfile: data,
                // privateProctorerId: ppid,
                fetchParticipantStatus: true,
                connectingMessage: Lang("Mempersiapkan Protokol Komunikasi ...", _this.state.lang, { en: `Preparing Communication Protocol ...` }),
                progressStateUser: "done",
                progressStateConn: "processing",
                privateMessage: _this.privateMessage.prepare(
                    `private-chat-${_this.context.profile.id}`,
                    _this.context.profile.id,
                    '')
            });
        }
    }

    this.onExamPreparationDone = (data, do_not_call_fetch_participant = false) => {
        Info("DATA = ", data);
        if (data !== null && data !== undefined && data.status === "success") {
            data = data.result;
            let ep;
            let url = [];
            try {
                url = JSON.parse(data.url)
            } catch (e) {

            }
            ep = {
                date: data.date,
                title: data.title,
                title_2: data["title-2"],
                startTime: data.start_time,
                start_time_ts: data.start_time_ts,
                preparationTime: data.preparation,
                preparation_time_ts: data.preparation_time_ts,
                duration: data.duration,
                end_time_ts: data.end_time_ts,
                video_resolution: data.video_resolution,
                video_quality: data.video_quality,
                url: url,
                direct_exam: data.redirect_on_preparation === 1,
                valid_by_default: data.valid_by_default === 1,
                validation_policy: data.validation_policy,
                interprocess_ai_delay: data.interprocess_ai_delay,
                time_warning: data.time_warning,
                face_detection_on_validation_thresh: data.face_detection_on_validation_thresh,
                face_detection_on_exam_thresh: data.face_detection_on_exam_thresh,
                primary_logo_url: data.primary_logo_url,
                secondary_logo_url: data.secondary_logo_url,
                ice_number: data.ice_number,
                show_warning: data.show_warning,
                useExtension: data.use_extension,
                useValidationId360: data.use_validation_id_360,
                url_in_iframe: data.url_in_iframe === 1,
                whitelist_tab: data.whitelist_tab,

                hide_chat_help_button: data.hide_chat_help_button === 1,
                hide_logos: data.hide_logos === 1,
                hide_reloadexam_button: data.hide_reloadexam_button === 1,
                hide_aiproctor_image: data.hide_aiproctor_image === 1,
                hide_logout_button: data.hide_logout_button === 1,

                audio_sensitivity: data.audio_sensitivity
            }

            _this.setState({
                examProfile: ep,
                fetchExamStatus: true,
                connectingMessage: Lang("Mendapatkan Informasi Peserta Ujian ...", _this.state.lang, { en: `Fetching Participant Profile ...` })
            });

            if (data.use_extension === 1) {
                const eurl = new URL(url[0]);
                _this.CE.register();
                _this.quizActivity.host = eurl.host;

                // do not update the chrome extension part also
                if (do_not_call_fetch_participant === false) {
                    // set default quiz url
                    _this.CE.setQuizUrl("amanin.exam");
                }
                let allowedUrls = [
                    "localhost",
                    "amanin.id",
                    "chrome://extensions/",
                    "chrome://",
                ]
                let newAllowedUrls = [];
                for (let i in ep.whitelist_tab) {
                    if (ep.whitelist_tab[i] && ep.whitelist_tab[i] !== '') {
                        try {
                            const turl = ep.whitelist_tab[i].includes("http") ? ep.whitelist_tab[i] : `https://${ep.whitelist_tab[i]}`;
                            const host = (new URL(turl)).host
                            newAllowedUrls.push(host)
                        } catch (e) {
                            Err(e)
                        }
                    }
                }
                allowedUrls.splice(0, 0, ...newAllowedUrls);
                _this.CE.setAllowedUrls(allowedUrls)
            }
        }

        fetchParticipantByParticipantId(_this.context.profile.uuid, (error) => {
            Debug("error fetch detail participant", error);
        }).then((data) => {
            setTimeout(() => {
                this.onUpdatePrivateProctorer(data)
                if (do_not_call_fetch_participant === false) {
                    this.onUserPreparationDone(data);
                }
            }, 1500)
        })
    }

    this.fetchExamProfileHandler = (do_not_call_fetch_participant = false) => {
        // fetchExamProfile
        Info("TTTTTEST GET ACCESS TOKEN = ", getAccessToken());
        fetchExamProfile((error) => {
            Err("[error] fetch exam", error);
        }).then((data) => {
            setTimeout(() => {
                this.onExamPreparationDone(data, do_not_call_fetch_participant);
            }, 1500)
        })
    }

    this.onAIPreparationDone = () => {
        _this.setState({
            initProktorAIStatus: true,
            progressStateAI: "done",
            progressStateUser: "processing",
            connectingMessage: Lang("Mendapatkan Informasi Ujian ...", _this.state.lang, { en: `Gathering Information ...` })
        });
        setTimeout(() => {
            Info("BBBBBBBBEFORE TTTTTEST GET ACCESS TOKEN = ", getAccessToken());

            this.fetchExamProfileHandler()
        }, 1000);
    }

    this.getIp = () => {
        getIPAddress((error) => { Debug("ERROR : ", error) }).then((data) => {
            _this.ipAddress = data.ip;
        })
    }

    this.updateTsRoutine = () => {
        if (Date.now() - _this.updateTsRoutineLastTs < 30000) {
            return;
        }

        if (_this.updateTsRoutineLastTs > 0 && Date.now() - _this.updateTsRoutineLastTs > 60000) {
            // close commonws, there is delayed in server
            Debug("close commonws because of updatetsroutinelastts")
            _this.commonWs.close();
            _this.commonWsAge = Date.now();
        }

        if (_this.commonWs !== null) {
            Debug("send update misc info");
            _this.commonWs.sendUpdateMiscInformation(_this.context.profile.id, {
                webcam: !!_this.state.userMediaStream,
                screencap: !!_this.state.displayMediaStream,
                response_time: avgResponseTime(),
                ts: Date.now()
            })
            _this.commonWs.sendUpdateTs(_this.context.profile.id, _this.ipAddress ? _this.ipAddress : "unknown");
            setTimeout(() => {
                _this.commonWs.sendUpdateTsPost(_this.context.profile.id, _this.ipAddress ? _this.ipAddress : "unknown", "participant", (v) => {
                    if (v) {
                        Debug("update ts post succeed")
                    } else {
                        Debug("update ts post failed")
                    }
                })
            }, 15000)
            this.getIp();
            const webrtcIpAddr = _this.webrtc['liveVideo']?.getVideoRouter()?.ip_address;
            if (_this.webrtc['liveVideo']) {
                _this.commonWs.sendUpdateVideoDistribution(
                    _this.context.profile.id,
                    _this.context.profile.proctor_id,
                    webrtcIpAddr,
                    JSON.stringify({})
                );
            }
        }
        if (_this.proctorerImageRef?.current !== null) {
            if (Date.now() - _this.proctorerImageRefLastTs > 60000 * 5/* 5 minute */) {
                _this.proctorerImageRef.current.src = `${urlProctorerRandom()}&time=${Date.now()}`;
                _this.proctorerImageRefLastTs = Date.now();
            }
        }

        this.getCurrentTsRoutine();

        // _this.updateTsRoutineLastTs will be updated on receive currentts value
    }

    this.recordToWebsocket = (streamWs, stream, enableAudio) => {
        const mimeType = _this.browser === "Safari" ? "video/mp4" : "video/webm";
        // const mimeType = _this.browser === "Safari" ? "video/mp4" : 'video/webm; codecs="vp8, vorbis'
        // const mimeType = "video/mp4";
        // const mimeType = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"'; // H.264 for video, AAC for audio

        let options = getVideoQualityOptions(
            _this.state.examProfile.video_quality,
            mimeType
            // ;codecs=h264"
            // "video/webm"
        );
        if (enableAudio === false) {
            options["audio"] = false;
        }
        Debug("options = ", options);
        let mediaRecorder = new MediaRecorder(stream, options);
        const BULK_DURATION = 30000
        mediaRecorder.start(BULK_DURATION);

        mediaRecorder.onstart = (e) => {
            // Debug("media recorder start", e);
        }

        mediaRecorder.ondataavailable = (blob) => {
            if (_this.record === true || (_this.state.initExam === true && _this.state.validationInProgress === true)) {
                streamWs.sendBlob(blob.data);
            } else {
                // Debug("do not record");
            }
        }
        return mediaRecorder;
    }

    this.sendImageToServerRoutine = (delay = 30000) => {

        if (Date.now() - _this.lastSendImageTs > delay) {
            const use_ts = Date.now();
            this.sendWebCamImageToServer(
                "face",
                use_ts,
                // force to store this picture if validation is in progress
                !!((_this.state.initExam === true && _this.state.validationInProgress === true)),
                () => {
                    // console.log("SEND IMAGE ROUTINE element to server done");
                    _this.lastSendImageTs = Date.now();
                }
            );

            if (_this.mode !== "mobile") {
                this.sendWebCamImageToServer(
                    "screen",
                    use_ts,
                    // force to store this picture if validation is in progress
                    !!((_this.state.initExam === true && _this.state.validationInProgress === true))
                )
            }

            // _this.lastSendImageTs = Date.now();
        }
    }

    this.playNewMessageSound = () => {
        _this.audioRef.current.play();
    }



    this.mediaStreamCheckHealth = async (id, videoEl) => {
        if (videoEl === null) {
            const confirmed = await ErrorAlert("Terjadi kesalahan pada kamera anda, sistem akan keluar. Anda dapat login kembali.");
            if (confirmed) {
                this.onLogout();
            }
        }
        if (videoEl.currentTime > _this.mediaStreamTs[id].ts) {
            _this.mediaStreamTs[id] = {
                ts: videoEl.currentTime,
                try: 0
            }
        } else {
            _this.mediaStreamTs[id].try++;
            if (_this.mediaStreamTs[id].try > 10) {
                Err("[error] ERROR DETECTED ON MEDIA STREAM id=", id, _this.mediaStreamTs[id].try);
            } else {
                // Debug(_this.mediaStreamTs);
            }

            if (_this.mediaStreamTs[id].try > 20) {
                const confirmed = ErrorAlert("Terjadi kesalahan pada kamera anda, sistem akan keluar. Anda dapat login kembali.")
                if (confirmed) {
                    this.onLogout();
                }
            }
        }
    }

    this.chatwsIndicator = () => {
        let v = _this.state.indicator;

        if (_this.chatWs !== null) {
            v.chatws = "black";
            if (_this.chatWs.ready() === false) {
                v.chatws = "green";
            }
            _this.setState({
                indicator:
                    v
            })
        } else {
            v.chatws = "green";
            _this.setState({
                indicator: v
            })
        }
    }


    this.commonwsIndicator = () => {
        let v = _this.state.indicator;

        if (_this.commonWs !== null) {
            v.commonws = "black";
            if (_this.commonWs.ready() === false) {
                v.commonws = "green";
            }
            _this.setState({
                indicator:
                    v
            })
        } else {
            v.commonws = "green";
            _this.setState({
                indicator: v
            })
        }
    }

    this.recordingIndicator = () => {
        let v = _this.state.indicator;

        if (_this.record) {
            v.recording = "black";
            _this.setState({
                indicator: v
            })
        } else {
            v.recording = "green";
            _this.setState({
                indicator: v
            })
        }
    }

    this.aiIndicator = () => {
        let v = _this.state.indicator;

        if (_this.record) {
            v.ai = "black";
            _this.setState({
                indicator: v
            })
        } else {
            v.ai = "green";
            _this.setState({
                indicator: v
            })
        }
        if (_this.state.eventState.event !== "normal") {
            v.ai = "blue";
            _this.setState({
                indicator: v
            })
        }
    }

    this.mediaStreamRoutine = () => {
        if (_this.state.userMediaStream !== null) {
            let el = null;
            if (_this.state.initExam === false) {
                el = document.getElementById("inputVideo");
            } else {
                el = _this.initWebCamRef.current;
            }
            this.mediaStreamCheckHealth("userMedia", el);
        }

        //SCREEN
        if (_this.state.displayMediaStream !== null && _this.mode !== "mobile") {
            let el = null;
            if (_this.state.initExam === false) {
                el = document.getElementById("inputDisplayVideo")
            } else {
                el = _this.initScreenCaptureRef.current;
            }
            this.mediaStreamCheckHealth("displayMedia", el);
        }

        this.chatwsIndicator();
        this.commonwsIndicator();
        this.recordingIndicator();
        this.aiIndicator();
    }

    this.getCurrentTsRoutine = () => {
        if (_this.commonWs !== null) {
            _this.commonWs.getCurrentTS(_this.context.profile.id);
        }
    }

    this.timesAlert = (currentTime, endTime) => {
        const timeRemaining = endTime - currentTime;

        if (_this.state.initExam === false && _this.state.timesAlert === false && timeRemaining < 300) {
            Debug("The time difference is less than 5 minutes.");
            AttentionAlert("Waktu Ujian Kurang dari 5 Menit Lagi !")
            _this.setState({ timesAlert: true, displayTimeAlertColor: "#dc143c" })
        }
    }

    this.sendImgElementToServer = (img, type = "face", ts = -1, cb) => {
        try {
            let canvas = elementToCanvas(img);
            let image = canvas.toDataURL('image/jpeg');

            if (type === "face") {
                _this._fileapi_.sendParticipantFaceImage(image, _this.record, true, ts, cb)
            }
            if (type === "screen") {
                _this._fileapi_.sendParticipantScreenImage(image, _this.record, true, ts, cb)
            }

            // if (_this.imageWs !== null) {
            //     if (_this.record === true) {
            //         if (
            //             _this.imageWs.sendParticipantImage(
            //                 _this.context.profile.id,
            //                 image,
            //                 type,
            //                 true,
            //                 ts
            //             ) === false) {
            //             _this.imageWs.close();
            //             _this.imageWs = null;
            //         }
            //     } else if (
            //         _this.imageWs.sendParticipantImageUnrecorded(
            //             _this.context.profile.id,
            //             image,
            //             type,
            //             true,
            //             ts
            //         ) === false) {
            //         _this.imageWs.close();
            //         _this.imageWs = null;
            //     }
            // }

            canvas.remove();
        } catch (e) {
            Err(e);
        }
    }

    this.sendWebCamImageToServer = async (type = "face", ts = null, called_from_store = false, cb) => {
        try {
            let els = null;
            if (type === "face") {
                els = _this.initWebCamRef.current;

                if (els === undefined || els === null) {
                    els = document.getElementById("inputVideo");
                    if (els === undefined || els === null) {
                        Err("inputVideo is not found")
                        return;
                    }
                }
                //SCREEN
            } else if (type === 'screen') {
                if (_this.mode !== "mobile") {
                    els = _this.initScreenCaptureRef.current;
                    if (els === undefined || els === null) {
                        els = document.getElementById("inputDisplayVideo");
                        if (els === undefined || els === null) {
                            Err("inputDisplayVideo is not found")
                            return;
                        }
                    }
                }
            } else if (type === 'mobilepairing') {
                els = _this.videoRef.current;
                Info("get mobile pairing = ", els);
                if (els === undefined || els === null) {
                    els = document.getElementById("mobilePairingVideo");
                    Info("get mobile pairing 2 = ", els);
                    if (els === undefined || els === null) {
                        Err("mobilePairingVideo is not found")
                        return;
                    }
                }
            }

            let use_ts = Date.now();
            if (ts !== null) {
                use_ts = ts;
            }
            let canvas = elementToCanvas(els);
            let image = canvas.toDataURL('image/jpeg');

            // compensation 620ms
            use_ts = use_ts - 620;
            // send participant image while in validation process,
            if (_this.record === true || _this.state.initExam === true) {
                if ((called_from_store === true) || (Date.now() - _this.lastSendImageWarningTs >= _this.DEFAULT_IMAGE_TO_SERVER_DELAY)) {

                    // console.log("SEND IMAGE ROUTINE 1 ");
                    if (type === "face") {
                        // Send participant face image via API
                        _this._fileapi_.sendParticipantFaceImage(image, _this.record, true, use_ts, cb);
                    } else if (type === "screen") {
                        // Send participant screen image via API
                        _this._fileapi_.sendParticipantScreenImage(image, _this.record, true, use_ts, cb);
                    } else if (type === "mobilepairing") {
                        // Send participant screen image via API
                        _this._fileapi_.sendParticipantMobilePairingImage(image, _this.record, true, use_ts, cb);
                    }
                } else {
                    Debug(`ignore send default image, because it's closed to last image warning diff_ts=${Date.now() - _this.lastSendImageWarningTs}`);
                    // console.log("SEND IMAGE ROUTINE, ignore send default image, because it's closed to last image warning ");
                }
            } else if (_this.record === false) {
                // console.log("SEND IMAGE ROUTINE 2 ");

                if (type === "face") {
                    _this._fileapi_.sendParticipantFaceImage(image, _this.record, false, use_ts, cb)
                } else if (type === "screen") {
                    _this._fileapi_.sendParticipantScreenImage(image, _this.record, false, use_ts, cb)
                } else if (type === "mobilepairing") {
                    // Send participant screen image via API
                    _this._fileapi_.sendParticipantMobilePairingImage(image, _this.record, true, use_ts, cb);
                }
            }

            // if (_this.record === true || _this.state.initExam === true) {
            //     // check if the last send image warning is > DEFAULT_IMAGE_TO_SERVER_DELAY (60000)
            //     if ((called_from_store === true) || (Date.now() - _this.lastSendImageWarningTs >= _this.DEFAULT_IMAGE_TO_SERVER_DELAY)) {
            //         if (_this.imageWs !== null && _this.imageWs.sendParticipantImage(_this.context.profile.id, image, type, false, use_ts) === false) {
            //             _this.imageWs.close();
            //             _this.imageWs = null;
            //         }
            //     } else {
            //         Debug("ignore send default image, because it's closed to last image warning");
            //     }
            // } else if (_this.record === false) {
            //     if (_this.imageWs !== null && _this.imageWs.sendParticipantImageUnrecorded(_this.context.profile.id, image, type, false, use_ts) === false) {
            //         _this.imageWs.close();
            //         _this.imageWs = null;
            //     }
            // }

            canvas.remove();
        } catch (e) {
            Err(e)
        }
    }

    this.setValidationStatus = async (validation_status) => {
        // Update API fetchExamProfile validation_status
        let pp = _this.state.participantProfile;
        if (pp.validation_status === validation_status) {
            return;
        }
        pp.validation_status = validation_status

        // 0 - invalid, 1 - valid, 2 - blocked
        _this.setState({ participantProfile: pp, showAlert: true });


        if ((pp.validation_status === 0 || pp.validation_status === 2) && _this.state.initExam === false) {
            if (_this.state.examProfile.useExtension === true) {
                _this.CE.setQuizUrl("amanin.exam");
            }
            const confirmed = await AttentionAlert("Status Validasi anda Tidak Valid, anda akan keluar dari Ruang Ujian. Tekan tombol bantuan pengawas untuk lebih jelasnya.")

            if (confirmed) {
                _this.setState({
                    initExam: true
                })

                _this._preparation.recoverVideoForPreparation();
                return;
            }

        }

        //Mematikan alert
        setTimeout(() => {
            _this.setState({ showAlert: false });
        }, 2000);
    }

    this.fetchParticipantRoutineLastTs = Date.now();
    this.fetchParticipantRoutine = () => {
        if (Date.now() - this.fetchParticipantRoutineLastTs > 30000) {
            fetchParticipantByParticipantId(_this.context.profile.uuid, (error) => {
                Debug("error fetch detail participant", error);
            }).then((data) => {
                if (data && data.status === "success") {
                    data = data.result

                    if (data.action === "logout") {
                        fetchSetAction(_this.context.profile.id, "", (err) => {
                            Err(err);
                        }).then((v) => {
                            _this._action.onLogout();
                        })
                    }
                    // change context
                    this.setValidationStatus(data.validation_status)

                    this.fetchParticipantRoutineLastTs = Date.now();

                    // also update exam profile value
                    // but do not fetch participant profile
                    this.fetchExamProfileHandler(true)
                }
            })
        }
    }

    this.sendDataToIframe = () => {
        this.sendMessageToIframe = () => {
            if (_this.iframeRef.current) {
                // Mengirim pesan berbentuk object
                const message = {
                    username: _this.directUser?.username,
                    password: _this.directUser?.password,
                }
                try {
                    _this.iframeRef.current?.contentWindow.postMessage(message, "*");
                } catch (error) {
                    Err(error)
                }

            }
        };

        if (_this.directUser && _this.iframeRef?.current) {
            // Kirim pesan saat iframe telah dimuat
            _this.iframeRef.current?.addEventListener('load', this.sendMessageToIframe());
            return () => {
                // Bersihkan event listener saat komponen di-unmount
                _this.iframeRef.current?.removeEventListener('load', this.sendMessageToIframe());
                // Hapus data di sessionStorage
                sessionStorage.removeItem('directUser');
            };
        }
    }

}

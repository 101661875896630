import React, { createRef } from "react";
import classes from "./ScreenshotCard.module.scss";
import { fetcDeleteScreenshotNotes, fetchUpdateScreenshotNotes, urlScreenShotList } from "../libs/proctor_utils";
import {
    LANG,
    convertTimestampToDateShort,
    convertTimestampToTime
} from "../libs/utils";
import PropTypes from "prop-types";
import { Lang } from "../libs/Language";
import { DecisionAlert, ErrorAlert, SuccessAlert } from "./Alert";
import { Debug } from "../libs/logger";

let console = {};
console.log = function () { };
console.error = function () { };

class ScreenshotCard extends React.Component {
    constructor(props) {
        super(props);
        this.notesRef = createRef()
        // this.notes = this.notesRef.current?.value
        this.state = {
            ready: false,
            edit: false,
            notes: this.props.data.notes
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                ready: true
            })
        }, this.props.index * 50)
        // Debug("Data : ", this.props.participantId)

    }

    handleEdit = () => {
        // Debug("Screenshot Update : ", this.notesRef.current.value)
        const { participantId, folderRand, data } = this.props;
        const notes = this.notesRef.current.value;

        Debug("Screenshot Update : ", {
            participantId: participantId,
            folderRand: folderRand,
            ts: data.ts,
            notes
        })
        fetchUpdateScreenshotNotes({
            participantId: participantId,
            folderRand: folderRand,
            ts: data.ts,
            notes
        }, (err) => {
            Debug("[ERROR]", err);
        }).then((data) => {
            Debug("Data handleEdit : ", data);

            if (data && data.status === 'success') {

                SuccessAlert(Lang("Data Berhasil Diupdate!", LANG, { en: `Data has been successfully updated` }));
                this.props.updateData(participantId, folderRand)
                this.setState({ edit: false })

            }
        }).catch(err => {
            Debug(err)
        })
    }

    handleDelete = async () => {

        const { participantId, folderRand, data, notes } = this.props;

        Debug("Screenshot Delete : ", {
            participantId: participantId,
            folderRand: folderRand,
            ts: data.ts,
            notes: notes
        })


        const confirmed = await DecisionAlert(Lang("Apakah anda ingin menghapus data ini ?", LANG, { en: `Do you want to delete this data ?` }), (Lang("Data Berhasil Dihapus!", LANG, { en: `Data has been successfully deleted.` })));

        if (!confirmed) {
            return;
        }

        await fetcDeleteScreenshotNotes({
            participantId: participantId,
            folderRand: folderRand,
            ts: data.ts
        }, (err) => {
            Debug("[ERROR]", err);
        }).then((data) => {
            Debug("Data handleDelete : ", data);
            if (data && data.status === 'success') {
                this.props.updateData(participantId, folderRand)
                return
            }
            ErrorAlert(data.errorMessage)
        }).catch(err => {
            Debug(err)
        })
    }

    onChangeEditButton = () => {
        this.setState({
            edit: true,
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data.notes !== prevState.notes) {
            return {
                notes: nextProps.data.notes,
            };
        }
        return null;
    }

    render() {
        Debug(this.state);
        if (this.state.ready === false) {
            return <></>
        }
        return <div className={classes.screenshot_wrapper} >
            <span>{convertTimestampToDateShort(this.props.data.ts)}{" "}{convertTimestampToTime(this.props.data.ts)}</span>
            <div className={classes.screenshot_image}
                onClick={() => this.props.imageModal(
                    [urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.face),
                    urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.screen)])
                }
                style={{ cursor: "pointer" }}>
                <img
                    alt="screen1"
                    src={urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.face)}
                />
                <img
                    alt="screen2"
                    src={urlScreenShotList(this.props.participantId, this.props.folderRand, this.props.data.screen)}
                />
            </div>
            {
                this.state.edit
                    ? <textarea
                        rows="3"
                        ref={this.notesRef}
                        defaultValue={this.state.notes}
                    />
                    :
                    <pre>
                        {this.state.notes}
                    </pre>
            }

            <div className={classes.btn_wrapper}>

                {this.state.edit
                    ? <button className={classes.btnOk} onClick={this.handleEdit}>{Lang(`Simpan`, LANG, { en: `Save` })}</button>
                    : <button className={classes.btnLink} onClick={() => this.onChangeEditButton()}>Edit</button>
                }
                <button className={classes.btnHelp} onClick={this.handleDelete}>{Lang(`Hapus`, LANG, { en: `Delete` })}</button>
            </div>
        </div>

    }
}

ScreenshotCard.propTypes = {
    index: PropTypes.number.isRequired,
    participantId: PropTypes.string.isRequired,
    folderRand: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    imageModal: PropTypes.func.isRequired
}

export default ScreenshotCard;
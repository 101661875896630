import call from "../../../assets/imgs/callwhite.png";
import flagwhite from "../../../assets/imgs/flagwhite.png";
import classes from "./Header.module.scss";
import { getPhotoFromLocal, urlFaceInWebCam } from "../../../libs/proctor_utils";
import star from "../../../assets/imgs/star.png";
import endcall from "../../../assets/imgs/endcall.png";

export const renderHeader = (_this) => {
    const profile = _this.state.chosen.profile;
    if (profile === undefined || profile === null) {
        return <></>;
    }

    let eh = []
    for (let e in _this.windowOptions) {
        const el = _this.windowOptions[e];
        eh.push(
            <img
                key={e}
                title={e}
                className={_this.state.chosenWindow === e ? classes.chosen : null}
                alt={e}
                src={el.img}
                onClick={() => {
                    if (e === 'chat') {
                        _this.props.onMessageAccessed(_this.state.chosen.profile.id);
                    }
                    _this.setState({
                        chosenWindow: e
                    })
                }}
            />
        )
    }

    const isOnline = ((Date.now() / 1000) - _this.state.chosen.profile.ts < 60)
    return <>
        <div className={classes.image}>
            <img alt="ktp" src={getPhotoFromLocal(profile?.photo)} />
            <img alt="face" src={urlFaceInWebCam(profile?.id, profile?.folder_rand)} />
        </div>
        <div className={classes.profile}>
            <div className={classes.profile_user}>
                <div className={classes.profile_user_id}>
                    <span className={classes.id}>{profile.id}</span>
                    <div className={classes.profile_user_id_validity}>
                        {_this.state.chosen.profile.validation_status ?
                            <div className={classes.valid}>valid</div> :
                            <div className={classes.invalid}>invalid</div>
                        }
                    </div>
                </div>
                <span>{profile.name}</span>
                <div className={classes.profile_user_info}>
                    {/* online/offline
                    perhatian khusus
                    highlight
                    chat jumlah
                    last chat message */}
                    {isOnline ?
                        <div className={classes.online}>online</div> :
                        <div className={classes.offline}>offline</div>
                    }
                    {_this.props.isHangup === true &&
                        <div className={classes.call}
                            onClick={() => {
                                _this.props.onCallParticipant(_this.state.chosen.profile.id)
                                console.log("xxx", _this.state.chosen.profile.id);
                            }}
                        >
                            <img alt="call" src={call} />
                        </div>
                    }
                    {_this.props.isHangup === false && <div className={classes.hangup}
                        onClick={() => {
                            _this.props.onCallParticipant(_this.state.chosen.profile.id)
                            console.log("xxx", _this.state.chosen);
                        }}
                    >
                        <img alt="call" src={endcall} />
                    </div>
                    }

                    <img alt="flagwhite" src={flagwhite} />
                    <img alt="star" src={star} />
                </div>
            </div>
        </div>
        <div className={classes.action}>
            {eh}
            {/* <img alt="collage" src={collage} />
            <img alt="chat" src={chat2} />
            <img all="notes" src={notes2} />
            <img all="screenshot" src={screenshot2} /> */}
        </div>
    </>;
}
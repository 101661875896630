import React from "react";
import classes from "./Troubleshooting.module.scss";
import troubleshooting from "../assets/imgs/troubleshooting.png";
import { Lang } from "../libs/Language";
import { LANG } from "../libs/utils";
import { exam_issues, exam_participant, extension, macbook_camera, macbook_microphone, macbook_screen_audio } from "../assets/imgs";

class Troubleshooting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // troubleshooting
            troubleshooting: {
                show: false,
                chosen: 0,
                hide_logos: props.hide_logos
            }
        }
    }

    render() {
        // console.log("URL : ", exam_participant)
        const trs = [
            {
                title: Lang("KENDALA SOAL UJIAN TIDAK TAMPIL DI MACOS", LANG(), { en: `` }),
                img_url: "https://amanin.id/troubleshootings_guidelines/tidaktampilmacos.png",
            },
            {
                title: Lang("Panduan Peserta Ujian", LANG(), { en: `Important Guidelines for Exam Participant` }),
                img_url: exam_participant,
            },
            {
                title: Lang("Hal - hal Yang Mungkin Terjadi Selama Ujian Online", LANG(), { en: `Possible Issues During Online Exams` }),
                img_url: exam_issues,
            },
            // {
            //     title: "KENDALA LOGIN MENUJU HALAMAN SOAL",
            //     img_url: "https://amanin.id/troubleshootings_guidelines/troubleshooting_login_soal.png"
            // },
            {
                title: Lang("Tidak dapat mengaktifkan kamera pada MacOS", LANG(), { en: `Macbook Camera Troubleshooting` }),
                img_url: macbook_camera
            },
            {
                title: Lang("Tidak dapat mengaktifkan Screen dan Audio pada MacOS", LANG(), { en: `Macbook Screen & Audio Recording Troubleshooting` }),
                img_url: macbook_screen_audio
            },
            {
                title: Lang("Tidak dapat mengaktifkan microphone pada MacOS", LANG(), { en: `Macbook Microphone Troubleshooting` }),
                img_url: macbook_microphone
            },
            {
                title: Lang("Bermasalah dengan Ekstensi Chrome", LANG(), { en: `Extension Troubleshooting` }),
                img_url: extension
            }
        ]

        return <div className={[classes.troubleshooting, classes.FadeInPage1s].join(" ")}>
            <div
                className={classes.troubleshooting_title}
                onClick={() => {
                    this.setState(prevState => ({
                        troubleshooting: {
                            ...prevState.troubleshooting,
                            ["show"]: !prevState.troubleshooting.show
                        }
                    }))
                }}>
                <img alt="troubleshooting" src={troubleshooting} />
                <span>troubleshooting</span>
            </div>
            {!this.state.troubleshooting.show ? <></> :
                <div className={[
                    classes.troubleshooting_content,
                    classes.FadeInPage1s
                ].join(" ")}
                    style={{ left: this.state.hide_logos ? "0px" : "-50px" }}
                >
                    {
                        trs.map((v, index) => {
                            return <div
                                key={v.title}
                                onClick={() => {
                                    this.setState(prevState => ({
                                        troubleshooting: {
                                            ...prevState.troubleshooting,
                                            ["chosen"]: index
                                        }
                                    }))
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight: this.state.troubleshooting.chosen === index ? "bold" : "normal"
                                    }}>{v.title}</span>
                            </div>
                        })
                    }
                    {this.state.troubleshooting.chosen !== -1 ?
                        <div className={[classes.img, classes.ascroll].join(" ")}>
                            <img
                                alt="troubleshooting_img"
                                src={trs[this.state.troubleshooting.chosen].img_url}
                            />
                        </div>
                        : <></>
                    }
                </div>
            }
        </div>
    }
}

export default Troubleshooting;
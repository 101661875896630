import React from "react";
import classes from "./ParticipantVideo.module.scss";
import { urlLiveScreen, urlWebCam } from "../../libs/proctor_utils";
import PropTypes from 'prop-types';
import { getWarningMessage, initHelpers, ParticipantVideoPropTypes, renderConfiguration, renderEmotion, renderLiveImageFace, renderLiveImageScreen, renderLiveMobilePairing, renderLiveVideoFace, renderLiveVideoScreen, renderMultiInformation, renderNewMessage, renderOnlineOffline, renderParticipantWarningInfo, renderPhotoId } from "./ParticipantVideo.helpers";

const DISABLE_FIXED_EL = true
// const DISABLE_FIXED_EL = false;

class ParticipantVideo extends React.Component {

  constructor(props) {
    super(props);

    initHelpers(this);

    this.loadImageInterval = null;
    this.imageUpdateCounter = 0;
    this.lastLoadImageTs = 0;

    this.alreadyDoneUpdateParticipantTs = false;


    this.pseudoContainerRef = React.createRef();
    this.containerRef = React.createRef();
    this.pseudoId = this.props.pseudoId;

    this.mutationObserver = null;

    this.state = {
      imgUrl: null,
      scrUrl: null,
      showConfiguration: false,
      showConfigurationModal: false
    };
  }

  // mountToPseudoLocation() {
  //   // let el = document.getElementById(`pseudo_${this.props.realId}`)
  //   if (!this.pseudoContainerRef || !this.pseudoContainerRef.current) {
  //     console.log("error pseudo")
  //     return;
  //   }
  //   let el = this.pseudoContainerRef.current;
  //   const r = el.getBoundingClientRect();
  //   console.log(r);
  //   const c = this.containerRef.current;
  //   c.style.top = `${Math.round(r.top)}px`;
  //   c.style.left = `${Math.round(r.left)}px`;
  // }

  updateStateStyleTopLeft() {
    let el = document.getElementById(`pseudo_${this.props.realId}`)
    if (!this.pseudoContainerRef || !this.pseudoContainerRef.current) {
      console.log("error pseudo")
      return;
    }
    // let el = this.pseudoContainerRef.current;
    const r = el.getBoundingClientRect();
    // console.log(r);
    // const c = this.containerRef.current;
    // c.style.top = `${Math.round(r.top)}px`;
    // c.style.left = `${Math.round(r.left)}px`;
    this.setState({
      styleTop: `${Math.round(r.top)}px`,
      styleLeft: `${Math.round(r.left)}px`
    })
  }

  getStyleTop() {
    return this.state.styleTop;
    if (!this.pseudoContainerRef || !this.pseudoContainerRef.current) {
      console.log("error pseudo")
      return `0px`;
    }
    let el = this.pseudoContainerRef.current;
    const r = el.getBoundingClientRect();
    return `${Math.round(r.top)}px`;
  }

  getStyleLeft() {
    return this.state.styleLeft;

    if (!this.pseudoContainerRef || !this.pseudoContainerRef.current) {
      console.log("error pseudo")
      return `0px`;
    }
    let el = this.pseudoContainerRef.current;
    const r = el.getBoundingClientRect();
    return `${Math.round(r.left)}px`;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    const el = document.getElementById(`participantContainer`)
    if (DISABLE_FIXED_EL === false) {
      if (el) {
        if (this.mutationObserver === null) {
          this.mutationObserver = new ResizeObserver((entries) => {
            this.updateStateStyleTopLeft();
          })
          this.mutationObserver.observe(el);
        }
      }
    }

    return;
    // console.log("CONTEXT = ", this.context.participantVideoUpdateRequired);
    // console.log(prevState)
    // this.mountToPseudoLocation();

    // const el = document.getElementById(`pseudo_${this.props.pseudoId}`)
    // const el = this.pseudoContainerRef.current
    // const el = document.getElementById(`participantContainer`)
    if (el) {
      if (this.mutationObserver === null) {

        this.mutationObserver = new MutationObserver(() => {
          console.log("there is mutation");
        })

        console.log("OBSERVE HERE");
        this.mutationObserver.observe(el, {
          attributes: true, // memonitor perubahan atribut
          childList: false, // memonitor penambahan/penghapusan anak elemen
          subtree: false, // memonitor seluruh subtree
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.loadImageInterval);
  }

  componentDidMount() {
    if (DISABLE_FIXED_EL === false) {
      const el = document.getElementById('participantContainer');
      el.addEventListener('scroll', (v) => {
        // console.log("on scroll");
        this.updateStateStyleTopLeft();
      })
    }

    this.setState({
      imgUrl: urlWebCam(this.props.participantId, this.props.folderRand, Date.now()),
      scrUrl: urlLiveScreen(this.props.participantId, this.props.folderRand, Date.now())
    })
    const COUNTER_STEP = 4;
    const STEP = 3;
    const MODULUS = COUNTER_STEP * STEP;
    // const DELAY = 3000;

    // fileapi sangat terbebani dengan read file dari storage, jika dipakai ribuan
    // get image per 1 menit untuk lebih leluasa
    // di masa depan, masukkan ke dalam konfigurasi,
    // untuk SM ITB 2024, load setiap 2 menit sekali
    const DELAY = 60000 / COUNTER_STEP

    // delay = DELAY * COUNTER_STEP / 1000 = delay
    // delay = 3000 * 4 = 12000 milisecond = 12 second -> di update per 12 detik
    clearInterval(this.loadImageInterval);
    this.loadImageInterval = setInterval(() => {
      this.checkUpdateParticipantTs();
      if (Date.now() - this.lastLoadImageTs < DELAY) {
        return;
      }
      this.lastLoadImageTs = Date.now();
      if (this.props.status === "offline") {
        return;
      }

      if (this.props.isParticipantModalOpen === true) {
        return;
      }
      // let a = convertStringIdToNumberId(this.props.participantId) % MODULUS;
      let a = this.props.seq % MODULUS;
      let b = this.imageUpdateCounter % COUNTER_STEP;
      if (a >= b * STEP && a < b * STEP + STEP) {
        this.updateImageAndScreen();
      }
      this.imageUpdateCounter++;
    },
      1000
      // DELAY
    );
  }

  updateImageAndScreen() {
    let updateScreen = false
    if (document.getElementById("img_" + this.props.proctorId).style.display !== "none") {
      updateScreen = true;
    }
    console.log(`update image and screen `, updateScreen)
    this.setState({
      imgUrl: urlWebCam(this.props.participantId, this.props.folderRand, Date.now()),
    })
    if (updateScreen) {
      this.setState({
        scrUrl: urlLiveScreen(this.props.participantId, this.props.folderRand, Date.now())
      })
    }
  }

  checkUpdateParticipantTs() {
    const diff = Date.now() - this.props.updateParticipantTs
    if (diff > 0 && diff < 1000 && this.alreadyDoneUpdateParticipantTs === false) {
      console.log("UPDATE PARTICIPANT TS = ", this.props.updateParticipantTs, Date.now())
      this.updateImageAndScreen();
      this.alreadyDoneUpdateParticipantTs = true;
    } else if (diff > 1000) {
      this.alreadyDoneUpdateParticipantTs = false;
    }
  }


  getWarningMessage() {
    return getWarningMessage(this);
  }

  renderConfiguration() {
    return renderConfiguration(this);
  }

  renderMultiInformation() {
    return renderMultiInformation(this);
  }

  renderEmotion(SW, width) {
    return renderEmotion(this, SW, width);
  }

  renderParticipantWarningInfo(TW, colorValidation, emotionLength, warningMessage) {
    return renderParticipantWarningInfo(this, TW, colorValidation, emotionLength, warningMessage);
  }

  renderNewMessage() {
    return renderNewMessage(this);
  }

  renderPhotoId(width) { return renderPhotoId(this, width) }

  renderLiveVideoFace(width) {
    return renderLiveVideoFace(this, width);
  }

  renderLiveImageFace(width) {
    return renderLiveImageFace(this, width);
  }

  renderLiveImageScreen(width) {
    return renderLiveImageScreen(this, width);
  }

  renderLiveVideoScreen(width) {
    return renderLiveVideoScreen(this, width);
  }

  renderOnlineOffline(width) {
    return renderOnlineOffline(this, width);
  }

  renderLiveMobilePairing(width) {
    return renderLiveMobilePairing(this, width);
  }

  render() {
    let TW = 1;

    if (this.props.showID === true) {
      TW++;
    }
    if (this.props.showScreen === true) {
      TW++;
    }

    if (this.props.showImage === true) {
      TW++;
    }

    let SW = 70;
    if (this.props.showEmotion === false || (this.props.emotion && this.props.emotion.calm === -1)) {
      SW = 0;
    }

    let width = 110;
    width = width * this.props.scale;
    let warningMessage = this.getWarningMessage();

    const valid = {
      background: "white",
      color: "black",
      textAlign: "center",
    }
    const invalid = {
      background: "#3c14dc",
      color: "white",
      textAlign: "center",
    }
    const colorValidation = this.props.validation === "valid" ? valid : invalid;
    const emotionLength = (this.props.showEmotion && SW !== 0) ? 15 : 0;

    const isLocalSearchMatch = this.props.localSearch !== "" && (this.props.participantId.toUpperCase().includes(this.props.localSearch?.toUpperCase()) || this.props.participantName.toUpperCase().includes(this.props.localSearch?.toUpperCase()));

    const WX = (width * TW + SW) + "px";
    const HX = (width * (0.8 * 2) + 50) + "px";
    return (
      <>
        {DISABLE_FIXED_EL === false && <div className={classes.container_pseudo}
          style={{
            width: WX,
            height: HX,
            border: isLocalSearchMatch ? "5px solid rgba(255, 128, 128, 0.9)" : "none"
          }}
          ref={this.pseudoContainerRef}
          id={`pseudo_${this.props.pseudoId}`}
          onWheel={() => {
            console.log("on wheel pseudo");
          }}
        >
          <span>{this.pseudoId}</span>
        </div>
        }
        <div
          ref={this.containerRef}
          className={classes.container}
          title={this.props.participantId + "-" + this.props.participantName}
          style={{
            // width: WX,
            // height: HX,
            // border: isLocalSearchMatch ? "5px solid rgba(255, 128, 128, 0.9)" : "none",
            top: this.getStyleTop(),
            left: this.getStyleLeft(),
            position: DISABLE_FIXED_EL === false ? "fixed" : "inherit"
          }}
          onMouseEnter={() => {
            this.setState({ showConfiguration: true })
          }}
          onMouseLeave={() => {
            this.setState({
              showConfiguration: false,
              showConfigurationModal: false
            })
          }}
          onWheel={(e) => {
            const cel = document.getElementById('participantContainer');
            cel.scrollTop += e.deltaY;
          }}
        >
          <div className={classes.container_v}
            style={{
              // width: (width * TW + SW) + "px",

              // height: (width * (0.8 * 2)) + "px",
              // height: (width * (0.8 * 1)) + "px",
              // border: "0px solid red"
            }}
          >
            {this.renderNewMessage()}
            {this.renderMultiInformation()}
            {this.renderConfiguration()}

            <div className={classes.container_v_row}
              style={{
                // width: (width * TW + SW) + "px",
                // height: (width * (0.8)) + "px",
              }}
            >
              {/* First Row */}
              {this.renderPhotoId(width)}

              {/* face */}
              {this.renderLiveVideoFace(width)}
              {this.renderLiveImageFace(width)}

              {/* screen */}
              {this.renderLiveVideoScreen(width)}
              {this.renderLiveImageScreen(width)}

              {/* mobile pairing */}
              {this.renderLiveMobilePairing(width)}

              {/* online offline */}
              {this.renderOnlineOffline(width)}
              {/* </div> */}

              {/* Second Row */}
              {/* <div
              className={classes.container_v_row}
              style={{
                width: (width * TW + SW) + "px",
                // height: (width * (0.8)) + "px",
              }}
            > */}
              {this.renderEmotion(SW, width)}
            </div>
          </div>
          {this.renderParticipantWarningInfo(TW, colorValidation, emotionLength, warningMessage)}
        </div>
      </>
    );
  }
}

ParticipantVideo.propTypes = {
  participantId: PropTypes.string.isRequired,
  folderRand: PropTypes.string.isRequired,
  isParticipantModalOpen: PropTypes.bool.isRequired,
  seq: PropTypes.number.isRequired,
  proctorId: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  showID: PropTypes.bool.isRequired,
  showScreen: PropTypes.bool.isRequired,
  showEmotion: PropTypes.bool.isRequired,
  emotion: PropTypes.object.isRequired,
  validation: PropTypes.string.isRequired,
  participantName: PropTypes.string.isRequired,
  status: PropTypes.string,
  showImage: PropTypes.bool,
  localSearch: PropTypes.string,
  pseudoId: PropTypes.number,
  ...ParticipantVideoPropTypes
}

export default ParticipantVideo;
import { Err, Info } from "../../../libs/logger"
import { fetchParticipantByParticipantId, urlLiveScreen, urlMobilePairing, urlWebCam } from "../../../libs/proctor_utils"

export const fetchDetailParticipant = (_this, uuid) => {
    fetchParticipantByParticipantId(uuid, (err) => {
        Err(err);
    }).then((v) => {
        if (v.status === 'success') {
            setTimeout(() => {
                const res = v.result;
                _this.setState({
                    isloading: false,
                    chosen: {
                        uuid: res.uuid,
                        profile: res
                    }
                })
                setTimeout(() => {
                    updateImage(_this);
                }, 100)

                setTimeout(() => {
                    loadPopupVideo(_this, "liveVideo")
                    loadPopupVideo(_this, "mobilePairing")
                    loadPopupVideo(_this, "screenCapture")
                }, 1000);
                _this.props.onChangeChosenParticipant(res);
            }, 100)
        }
    })
}

export const updateImage = (_this) => {
    _this.setState({
        screenImgUrl: urlLiveScreen(_this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand, Date.now()),
        faceImgUrl: urlWebCam(_this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand, Date.now()),
        mobilePairingImgUrl: urlMobilePairing(_this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand, Date.now()),
    })
}

export const loadPopupVideo = (_this, type /* liveVideo, mobilePairing, screenCapture*/) => {
    const elid = `${type}_proctor${_this.state.chosen.profile?.xrcp}${_this.state.chosen.profile?.id}`
    const el = document.getElementById(elid);
    const deskid = `${type}Desk`

    setTimeout(() => {
        const elx = document.getElementById(deskid)
        if (elx !== null && elx !== undefined) {
            if (elx.srcObject === null) {
                document.getElementById(deskid).srcObject = el?.srcObject;
            }
        } else {
            loadPopupVideo(_this, type);
        }
    }, 1000)
}


import Chat from "../../../components/Chat/Chat";
import classes from "./Chat.module.scss";

export const renderChat = (_this) => {
    return <div className={classes.container}>
        <div className={[classes.container_first, classes.ascroll].join(" ")} >
            <img
                width="320px"
                alt={"face"}
                src={_this.state.faceImgUrl}
            />
            <img
                width="320px"
                alt="screen"
                src={_this.state.screenImgUrl} />
            <img
                width="320px"
                alt="mobilepairing"
                src={_this.state.mobilePairingImgUrl} />
        </div>
        <div className={classes.container_second}>
            {_this.props.children}
        </div>
    </div>
}
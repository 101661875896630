import classes from "./Collage.module.scss";
import face from "../../../assets/imgs/proctorer.png";
import speaker from "../../../assets/imgs/audioon.png";
import mute from "../../../assets/imgs/audiooff.png";

export const renderCollage = (_this) => {
    return <div className={[classes.container, classes.FadeInPage1s].join(" ")}>
        <div className={classes.left}>
            <span>Face</span>
            <div className={classes.img}>
                <img
                    width="100%"
                    alt={"face"}
                    src={_this.state.faceImgUrl}
                />
            </div>
            <video
                // controls
                id="liveVideoDesk"
                autoPlay
                // height="200px"
                muted={_this.state.isAudioMuted}
            >
                <track kind='captions' />
            </video>
            <div
                className={classes.audio}
                onClick={() => {
                    _this.setState(prevState => ({
                        isAudioMuted: !prevState.isAudioMuted
                    }));
                }}
            >
                {
                    _this.state.isAudioMuted ?
                        <img alt="mute"
                            // width={"30px"} 
                            src={mute} /> :
                        <img alt="unmute"
                            // width={"30px"} 
                            src={speaker} />
                }
            </div>
        </div>
        <div className={classes.right}>
            <div className={classes.screen}>
                <span>Screen</span>
                <div className={classes.horiz}>
                    <div className={classes.img}>
                        <img
                            width="100%"
                            alt="screen"
                            src={_this.state.screenImgUrl} />
                    </div>
                    <div>
                        <video
                            id="screenCaptureDesk"
                            autoPlay
                            height="320px"
                            muted={_this.state.isAudioMuted}>
                        </video>
                    </div>
                </div>
            </div>
            <div className={classes.screen}>
                <span>Mobile Pairing</span>
                <div className={classes.horiz}>
                    <div className={classes.img}>
                        <img
                            width="100%"
                            alt="mobile pairing"
                            style={{maxHeight: "240px"}}
                            src={_this.state.mobilePairingImgUrl}
                        />
                    </div>
                    <div>
                        <video
                            id="mobilePairingDesk"
                            autoPlay
                            height="320px"
                            muted={_this.state.isAudioMuted}>
                        </video>
                    </div>
                    {/* <video controls /> */}
                </div>
            </div>
        </div>
    </div>
}
import classes from "./PrivateCall.module.scss";
import call from "../../assets/imgs/callwhite.png";
import { convertDurationToBetterFormatInt2 } from "../../libs/utils";
import { Lang } from "../../libs/Language";
import { Rnd } from "react-rnd";

export const __PrivateCall__ = function (_this) {
    let duration = 0;
    let proctorId = "";
    this.routine = () => {
        const privateCall = _this.webrtc['privateCall']
        const audioEl = document.getElementById("audioCaller");
        if (privateCall === null) {
            duration = 0;
            proctorId = "";
        } else if (privateCall.connectedUserDetails) {
            proctorId = privateCall.connectedUserDetails.proctorId.replace("private_", "").replace(`_${_this.context.profile.id}`, "");
            duration++;
        }
    }

    this.renderCall = () => {
        return <div className={classes.container}>
            <div style={{
                position: "fixed",
                visibility: "hidden"
            }}
            >
                <audio
                    muted={false}
                    autoPlay={true}
                    id="audioCaller"
                    onPlay={() => {
                        console.log("on play")
                    }}
                    onPlaying={() => {
                        console.log("on playing")
                    }}
                    onPause={() => {
                        console.log("on pause");
                    }}
                    onEmptied={() => {
                        console.log("on emptied")
                    }}
                    onStalled={() => {
                        console.log("on stalled");
                    }}
                    onError={() => {
                        console.log("on error");
                    }}
                />
            </div>
            {
                proctorId !== "" &&
                <div className={classes.container_content}>
                    <div className={classes.container_content_top}>
                        <div className={classes.time}>
                            <img alt="call" src={call} />
                            <span>{convertDurationToBetterFormatInt2(duration)}</span>
                        </div>
                        <span>Terhubung dengan {proctorId}</span>
                    </div>
                    {
                        _this.state.userMediaStream === null &&
                        <div className={classes.bottom}>
                            <div
                                className={classes.btnOk}
                                onClick={() => {
                                    _this._preparation.handleActiveShareVideo();
                                }}>
                                {Lang("Device anda belum aktif, klik di sini untuk mengaktifkan Web Camera dan Screen Capture", _this.state.lang, { en: `Your devices are not active, Click Here to Activate Web Camera and Screen Capture` })}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    }

    this.render = () => {
        return this.renderCall();
        return <Rnd
            default={{
                x: 100,
                y: 100,
                width: 320,
                height: 200,
            }}
            minWidth={200}
            minHeight={100}
            bounds="window"
        >
            <div style={{ background: 'lightblue', width: '100%', height: '100%' }}>
                {this.renderCall()}
            </div>
        </Rnd>
    }
}
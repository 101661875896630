import React, { Component } from 'react'
import { fetchSaveFaceInCamImage, fetchSaveMobilePairingImage, fetchSaveParticipantImage, fetchSaveScreenImage } from '../../libs/proctor_utils';

export const __FileApi__ = function (_this) {

    this.faceIncamValidation = (img, cb) => {
        fetchSaveFaceInCamImage({
            id: _this.context.profile.id,
            folder_rand: _this.context.profile.folder_rand,
            image: img,
        }, (err) => {
            console.error("[ERROR]", err);
        }).then(cb)
    }

    this.sendParticipantFaceImage = (img, unrecorded, is_warning = false, ts, cb) => {
        fetchSaveParticipantImage({
            id: _this.context.profile.id,
            image: img,
            folder_rand: _this.context.profile.folder_rand,
            unrecorded: !unrecorded,
            is_warning: is_warning,
            ts: ts,
        }, (err) => {
            console.error("[ERROR]", err);
        }).then(cb)
    }

    this.sendParticipantScreenImage = (img, unrecorded, is_warning = false, ts, cb) => {
        fetchSaveScreenImage({
            id: _this.context.profile.id,
            image: img,
            folder_rand: _this.context.profile.folder_rand,
            unrecorded: !unrecorded,
            is_warning: is_warning,
            ts: ts,
        }, (err) => {
            console.error("[ERROR]", err);
        }).then(cb)
    }

    this.sendParticipantMobilePairingImage = (img, unrecorded, is_warning = false, ts, cb) => {
        fetchSaveMobilePairingImage({
            id: _this.context.profile.id,
            image: img,
            folder_rand: _this.context.profile.folder_rand,
            unrecorded: !unrecorded,
            is_warning: is_warning,
            ts: ts,
        }, (err) => {
            console.error("[ERROR]", err);
        }).then(cb)
    }

}


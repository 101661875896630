import React from "react";
import classes from "./SlashCommand.module.scss";
import search from "../../assets/imgs/search.png";
import PropTypes from 'prop-types';
import AuthContext from "../../store/auth-context";
import info from "../../assets/imgs/info.png"
import FormCommandChat from "./FormCommandChat";
import { DecisionAlert, ErrorAlert, SuccessAlert } from "../Alert";
import Swal from "sweetalert2";
import { createRoot } from 'react-dom/client';
import { fetchCreateCommandChat, fetchDeleteCommandChat, fetchUpdateCommandChat } from "../../libs/proctor_utils";
import editIcon from '../../assets/imgs/editIcon.png';
import deleteIcon from '../../assets/imgs/deleteIcon.png';
import { Lang } from "../../libs/Language";
import { LANG } from "../../libs/utils";

class SlashCommand extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.slashRef = React.createRef();
        this.contentRef = React.createRef();
        this.searchRef = React.createRef();

        this.state = {
            showContent: false,
            searchText: "",
            selectedCommandChat: null,
        }

    }


    matchStrings(searchText, code, codeText, role) {

        if (!searchText) {
            return true; // Menampilkan semua item ketika tidak ada teks pencarian
        }

        searchText = searchText.replace(/^\//, '');
        code = code.replace(/^\//, '');
        const searchTextUpper = searchText.toUpperCase();


        return code.toUpperCase().includes(searchTextUpper) ||
            codeText.toUpperCase().includes(searchTextUpper) ||
            role === (searchTextUpper === 'ADMIN' ? 1 : "")
    }


    componentDidUpdate(v) {
        if (v.showMe === true && this.state.showContent === false) {
            console.log("show content on update set true");
            this.adjustModalPosition();
            this.setState({ showContent: true })
        } else if (v.showMe === false && this.state.showContent === true) {
            console.log("show content on update set false");
            this.setState({ showContent: false })
        }

        if (this.state.showContent === true && v.searchText !== "") {
            this.searchRef.current && (this.searchRef.current.value = v.searchText)
        }
        if (this.searchRef.current?.value !== "") {
            if (this.state.searchText !== this.searchRef.current?.value) {
                this.setState({ searchText: this.searchRef.current?.value })
            }
        }
    }

    onClose() {
        this.setState({
            selectedCommandChat: null,
        })
        Swal.close();
    }

    adjustModalPosition() {
        const slashRect = this.slashRef.current.getBoundingClientRect();
        const content = this.contentRef.current;
        if (!content) {
            return;
        }
        content.style.top = '5%';
        content.style.zIndex = '999';
        if (this.props.position === "right") {
            content.style.left = '250px';
        } else {
            content.style.left = Math.round(slashRect.right - 518) + 'px';
        }
    }

    handleCreateCommandChat(data) {

        fetchCreateCommandChat(data).then((response) => {
            if (response.status === "success") {
                SuccessAlert("Berhasil Menambahkan Command Chat!").then(() => {
                    this.props.getCommandChat()
                    this.onClose();
                });
                return;
            }
            ErrorAlert(response.errorMessage)
            return
        }).catch(error => {
            ErrorAlert(error.message)
        })
    }


    handleUpdateCommandChat(data) {

        fetchUpdateCommandChat(data, (err) => {
            console.error("[ERROR]", err);
        }).then((response) => {
            if (response?.status === "success") {
                SuccessAlert("Sukses..! Update Data Berhasil").then(() => {
                    this.props.getCommandChat()
                    this.onClose();
                });
                return;
            }
            ErrorAlert(response.errorMessage)
            return;
        }).catch(error => {
            // console.log(error.message)
            ErrorAlert(error.message)
        })

    }

    handleDeleteCommandChat = async (command) => {

        const confirmed = await DecisionAlert(Lang(`Apakah anda ingin menghapus command ${command.command} ?`, null));

        if (confirmed) {

            fetchDeleteCommandChat({ id: command.id }, (err) => {
                console.error("[ERROR]", err);
            }).then((response) => {
                if (response?.status === "success") {
                    SuccessAlert(Lang("Data Berhasil Dihapus!", LANG, { en: `Data has been successfully deleted.` }))
                        .then(() => {
                            this.props.getCommandChat()
                            this.onClose();
                        });
                    return;
                }
                ErrorAlert(response.errorMessage)

            })
        }
    }

    formModal = (title, titleButton, selectedCommandChat = null) => {
        this.setState({
            showContent: false
        })
        Swal.fire({
            title: `${title} Command Chat`,
            html: '<div id="form-container"></div>',  // Placeholder element for rendering
            showCancelButton: false,
            showConfirmButton: false,
            customClass: {
                popup: 'swal2-custom-popup'
            },
            didOpen: () => {
                const container = document.getElementById('form-container');
                const root = createRoot(container);

                root.render(
                    <FormCommandChat
                        data={selectedCommandChat}
                        titleButton={titleButton}
                        onCreate={(data) => {
                            selectedCommandChat !== null ?
                                this.handleUpdateCommandChat(data)
                                : this.handleCreateCommandChat(data)
                        }}
                        onClose={() => {
                            this.onClose();
                            root.unmount();
                        }}
                    />
                );
            },
        });
    };

    render() {
        // console.log("RESULT : ", this.context.profile.id.includes("admin"))
        let el = [];
        const searchText = this.state.searchText.trim();

        this.props.listCommand?.forEach((group, groupIndex) => {
            // this.listCommand?.forEach((group, groupIndex) => {

            // Cek apakah topik cocok dengan searchText
            const isTopicMatch = group.topic.toUpperCase().includes(searchText.toUpperCase());
            // Jika topik cocok, tampilkan semua item dari topik tersebut
            const filteredItems = isTopicMatch ? group.items : group.items.filter((command) =>
                this.matchStrings(searchText, command.command, command.response, parseInt(command.access_role))
            );

            // Jika tidak ada teks pencarian, atau ada item yang cocok, atau topik cocok, tampilkan topik dan itemnya
            if (filteredItems.length > 0 || isTopicMatch || !searchText) {
                let topicHeader = (
                    <div key={`group-${groupIndex}`} className={classes.topicHeader}>
                        {group.topic}
                    </div>
                );

                el.push(topicHeader);

                filteredItems.forEach((command, index) => {
                    el.push(
                        <div
                            key={`${groupIndex}-${index}`}
                            onClick={() => {
                                this.props.onChosenText(command.response);
                                this.setState({ showContentFromClick: false });
                            }}
                            className={classes.item}
                        >
                            <div className={classes.code}>
                                <span>{command.command}</span>
                                <div className={classes.infoContainer}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img src={info} alt="info" className={classes.infoIcon} />
                                        <span style={{ marginLeft: "5px", color: "yellowgreen" }}>{
                                            command.access_role === 1 ? "Admin" : ""
                                        }</span>
                                    </div>
                                    <div className={classes.tooltip}>
                                        {command.description}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.content}>{command.response}</div>

                            {
                                this.context.profile.id.includes("admin") &&
                                <div className={classes.action_icon} onClick={(e) => {
                                    e.stopPropagation()
                                }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <img alt="edit" title="Edit" src={editIcon} height="20px" width="20px" onClick={(e) => {
                                            this.formModal("Update", "Update", {
                                                id: command.id,
                                                topic: group.topic,
                                                command: command.command,
                                                description: command.description,
                                                response: command.response,
                                                role: command.access_role
                                            })
                                        }} />
                                        <span>Edit</span>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                                        <img alt="delete" title="Delete" src={deleteIcon} height="20px" width="20px" onClick={(e) => {
                                            this.handleDeleteCommandChat(command)
                                        }} />
                                        <span>Delete</span>
                                    </div>

                                </div>
                            }

                        </div>


                    );

                    // }
                });

            }

        });



        return <div className={[classes.container, classes.FadeInPage1s].join(" ")}>
            <div
                className={classes.container_slash}
                ref={this.slashRef}
                onClick={() => {
                    this.adjustModalPosition();
                    this.setState(prevState => ({
                        showContentFromClick: !prevState.showContentFromClick
                    }));
                }}
            >/</div>

            {
                /*(this.state.showContent || this.state.showContentFromClick) && */<div
                    ref={this.contentRef}
                    className={[classes.container_content, classes.FadeInPage1s].join(" ")}
                    style={{
                        visibility: (this.state.showContent || this.state.showContentFromClick) ? "visible" : "hidden",
                        opacity: (this.state.showContent || this.state.showContentFromClick) ? "1" : "0"
                    }}
                >
                    <div className={classes.container_content_search}>
                        <img alt="search" src={search} />
                        <input
                            ref={this.searchRef}
                            type="text"
                            placeholder="search"
                            onChange={() => {
                                const el = this.searchRef.current;
                                this.setState({ searchText: el.value })
                            }}
                        />
                        {this.context.profile.id.includes("admin") &&
                            <div
                                onClick={() => {
                                    this.setState({
                                        showFormCommandChat: true,
                                    })
                                    this.formModal("Create New", "Create")
                                }}
                            >create</div>
                        }

                        <div
                            onClick={() => {
                                if (this.props.onClose) {
                                    this.props?.onClose();
                                }
                                this.setState({
                                    showContentFromClick: false,
                                    showContent: false
                                })
                            }}
                        >close</div>
                    </div>
                    <div className={[classes.container_content_list, classes.ascroll].join(" ")}>
                        {el}
                    </div>

                </div>
            }
        </div>
    }
}

SlashCommand.propTypes = {
    position: PropTypes.string,
    onChosenText: PropTypes.func,
    onClose: PropTypes.func,
    listCommand: PropTypes.array,
    getCommandChat: PropTypes.func

}

export default SlashCommand;
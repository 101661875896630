import { getAccessToken } from "../utils";

export function registerClient(_this, type, id, listOfParticipants = null) {
    // Debug("[register] type=" + type + ", id=" + id)
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: type,
                id: id,
                listOfParticipants: listOfParticipants,
                token: getAccessToken()
            })
        )
    } else {
        // Debug("[register] socket is not ready");
        return false;
    }
    return true;
}

export function registerParticipant(_this, id) {
    return _this.registerClient("register-participant", id);
}

export function registerParticipantMobilePairing(_this, id) {
    return _this.registerClient("register-participant-mobile-pairing", id);
}
export function registerProctorer(_this, id, listOfParticipants) {
    return _this.registerClient("register-proctorer", id, listOfParticipants);
}
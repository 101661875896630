import React, { Component } from 'react';
import classes from "./__index__.module.scss";
import AuthContext from '../../store/auth-context';
import { __ActionMp__ } from './Action';
import { __Component__ } from './Component';
import { Info } from '../../libs/logger';
import { __CommonWs__ } from '../ExamPage/CommonWs/CommonWs';
import { __FileApi__ } from '../ExamPage/FileApi';
import { __Action__ } from '../ExamPage/Action';

class MobilePairingPage extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            useFrontCamera: true,
            isSpinning: false,
            reverseSpin: false,
        };
        this.videoRef = React.createRef();
        this.stream = null;
        this.commonWs = null;

        // to enable send video to server
        this.record = true;
        this.DEFAULT_IMAGE_TO_SERVER_DELAY = 30000;

        this._actionmp = new __ActionMp__(this, classes);
        this._component = new __Component__(this, classes);
        this._commonws = new __CommonWs__(this);
        this._fileapi_ = new __FileApi__(this);
        this._action = new __Action__(this, classes);
    }

    componentDidMount() {
        this._actionmp.startVideoStream();

        clearInterval(this.commonwsInterval);
        this.commonwsInterval = setInterval(() => {
            this._commonws.commonWsRoutine();

            this._actionmp.sendImageToServerRoutine(30000)
        }, 1000)
    }

    componentWillUnmount() {
        this._actionmp.stopVideoStream();
    }

    render() {
        if ( this.context.profile.id === undefined || this.context.profile.id === null ) {
            window.location.href = '/'
        }
        return (
            <div className={classes.fullscreen_container}>
                <div className={classes.fullscreen_container_v}
                // style={{ height: "140px" }}
                >
                    <video
                        ref={this.videoRef}
                        id="mobilePairingVideo"
                        autoPlay
                        muted
                        className={classes.fullscreen_video}

                    />
                </div>
                {/* <div>
                    {this._component.navbar()}
                </div> */}
            </div>
        );
    }
}

export default MobilePairingPage;

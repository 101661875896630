import ChromeExtension from "../../libs/ChromeExtension";
import { Debug } from "../../libs/logger";

export const __ChromeExtension__ = function (_this, classes) {
    this.counter = 0;
    _this.CE = new ChromeExtension();
    _this.quizActivity = {
        host: null,
        tab: false,
        windowFocused: false
    }
    this.tabsHandler = (tabs) => {
        let oneActive = false;
        tabs.forEach((t) => {
            if (t.active) {
                if (t.url.includes(window.location.host) === false) {
                    if (_this.quizActivity.host !== null) {
                        if (t.url.includes(_this.quizActivity.host) === true) {
                            Debug("quiz page detected");
                            oneActive = true;
                            _this.quizActivity.tab = true;
                        } else {
                            Debug(this.counter++, "check = ", t.url, _this.quizActivity.host)
                            _this.quizActivity.tab = false;
                        }
                    }
                } else if (t.url.includes("proctor")) {
                    Debug("ignore for proctorer")
                } else {
                    Debug("current system detected");
                    _this.quizActivity.tab = true;
                    oneActive = true;
                }
            }
        })
        if (oneActive === false) {
            Debug("one active false");
            _this.quizActivity.tab = false;
        }
    }

    this.chromeExtensionRoutine = () => {
        if (_this.state.examProfile.useExtension) {
            _this.CE.checkExtension((result) => {
                _this.setState({ extensionInstalled: result })
            });
            _this.CE.register();
            _this.CE.getTabs((tabs) => {
                this.tabsHandler(tabs)
            })
            _this.CE.getWindowsFocused((v) => {
                _this.quizActivity.windowFocused = v;
            })
        }
    }
}




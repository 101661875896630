import React from 'react';
import classes from "./__index__.module.scss";
import face from "../../../assets/imgs/proctorer.png";
import { Info } from '../../../libs/logger';
import { renderHeader } from './Header';
import user from "../../../assets/imgs/user.png";
import star from "../../../assets/imgs/star.png";
import flag from "../../../assets/imgs/flagwhite.png";
import PropTypes from 'prop-types';
import { urlFaceInWebCam } from '../../../libs/proctor_utils';
import { fetchDetailParticipant, loadPopupVideo, updateImage } from './Action';
import { renderSearch, renderWindow } from './Component';

import notes2 from "../../../assets/imgs/notes2.png";
import screenshot2 from "../../../assets/imgs/screenshot2.png";
import collage from "../../../assets/imgs/collage.png";
import chat2 from "../../../assets/imgs/chat2.png";
import { renderMenu } from './LeftMenu';
import { renderParticipant } from './Participant';
import { onCallParticipant } from '../PrivateCall';


class ParticipantDesk extends React.Component {
    constructor(props) {
        super(props);

        this.menus = {
            participants: {
                title: 'Participant',
                img: user
            },
            highlight: {
                title: 'Highlight',
                img: star
            },
            flag: {
                title: 'Flag',
                img: flag
            }
        };


        this.participants = [
            {
                id: '23100001',
                name: 'Deddi Hariprawira',
                img: face,
                chat: "proctor00001: Oke akan kami cek terlebih dahulu",
            },
            {
                id: '24100001',
                name: 'Saidah Nurul Laili',
                img: face
            },
            {
                id: 'deddihp@gmail.com',
                name: 'Amira Sharliz Nisrina Hariprawira',
                img: face
            },
            {
                id: '2931238127763478',
                name: 'Muhammad Arkhan Ibrahim Hariprawira',
                img: face
            },
            {
                id: '2310989091',
                name: 'Salwa Nabila Humaira Hariprawira',
                img: face
            }
        ]

        this.participants = {};
        this.updateParticipants();

        this.state = {
            participants: this.participants,
            chosen: {
                uuid: this.props.participantUUId,
                profile: null,
            },
            isAudioMuted: false,
            isloading: true,

            chosenWindow: 'collage', // collage, notes, screencapture, chat
            chosenMenu: 'participants',

            faceImgUrl: null,
            screenImgUrl: null,
            mobilePairingImageUrl: null,
        };

        this.windowOptions = {
            'collage': {
                img: collage,
            },
            'chat': {
                img: chat2
            },
            'notes': {
                img: notes2
            },
            'screenshot': {
                img: screenshot2
            }
        }

        setInterval(() => {
            this.updateParticipants();
            this.setState({ participants: this.participants });
        }, 10000);
    }

    updateParticipants() {
        for (let p in this.props.participants) {
            const par = this.props.participants[p];
            this.participants[p] =
            {
                id: p,
                uuid: par.uuid,
                name: par.username,
                img: urlFaceInWebCam(p, par.folder_rand),
                status: par.status,
                validation_status: par.validation_status
            }
            //)
        }
        // this.setState({ participants: this.participants });
    }

    componentDidMount() {
        fetchDetailParticipant(this, this.state.chosen.uuid);

        setTimeout(() => {
            updateImage(this);
        }, 1000);

        //make flicker
        clearInterval(this.getImageInterval);
        this.getImageInterval = setInterval(() => {
            // make flicker inside
            fetchDetailParticipant(this, this.state.chosen.uuid)

            // doesn't make flicker
            updateImage(this);

            // send store participant 
            this.props.onSendStoreParticipantImage(this.state.chosen.profile.id)
        }, 5000);

        setTimeout(() => {
            const el = document.getElementById(this.state.chosen.uuid)
            el?.scrollIntoView(
                { behavior: "smooth" }
            );
        }, 1000);
    }

    componentDidUpdate() {
    }

    render() {
        return <div className={[classes.container, classes.FadeInPage1s].join(" ")}>
            <div className={classes.container_body}>
                <div className={classes.container_body_menu}>
                    {renderMenu(this)}
                </div>
                <div className={classes.container_body_participant}>
                    <div className={classes.container_body_participant_search}>
                        {renderSearch()}
                    </div>
                    <div className={classes.container_body_participant_par}>
                        {renderParticipant(this)}
                    </div>
                </div>
                <div className={classes.container_body_content}>
                    <div className={classes.container_body_content_header}>
                        {renderHeader(this)}
                    </div>
                    <div className={classes.container_body_content_body}>
                        {renderWindow(this)}
                    </div>
                </div>
            </div>
        </div>
    }
}

ParticipantDesk.propTypes = {
    onClose: PropTypes.func,
    participantUUId: PropTypes.string,
    participants: PropTypes.object,
    onCallParticipant: PropTypes.func
}

export default ParticipantDesk;